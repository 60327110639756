.hs-unfold-content {
  display: block !important;
  opacity: 0;
  pointer-events: none;
}

.hs-unfold-content-initialized {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.hs-unfold--hidden {
  display: none !important;
  opacity: 0;
  visibility: hidden;
}

.hs-unfold-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 101;
  display: none;
  background-color: rgba(0, 0, 0, 0.75);
}

.dropdown-unfold {
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: .5rem;
}

.dropdown-unfold::before {
  content: "";
  position: absolute;
  bottom: calc(100% - 2px);
  left: 0;
  display: block;
  width: 100%;
  height: 18px;
}

.dropdown-unfold.hs-unfold--reverse-y {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: .5rem;
}

.dropdown-unfold.hs-unfold--reverse-y::before {
  top: calc(100% - 2px);
  bottom: auto;
}

/*------------------------------------
  Animations
------------------------------------*/
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 15px, 0);
            transform: translate3d(0, 15px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 15px, 0);
            transform: translate3d(0, 15px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
}

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}

@-webkit-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 10px, 0);
            transform: translate3d(0, 10px, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

.slideInUp {
  -webkit-animation-name: slideInUp;
          animation-name: slideInUp;
}

@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -10px, 0);
            transform: translate3d(0, -10px, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

.slideInDown {
  -webkit-animation-name: slideInDown;
          animation-name: slideInDown;
}