// user-variables.scss
// Use this to overwrite Bootstrap and Front variables
//

// Example of a variable override to change Front's primary color and font size
// Remove the "//" to comment it in and see it in action!
// $primary: red;
$font-size-base: 0.95rem;

// create new variable bg-black in the bootstrap variables
$bg-black: #000000;
.bg-black {
  background-color: $bg-black !important; // Asegura que tiene prioridad
}
