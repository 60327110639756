/*------------------------------------
  List Unstyled
------------------------------------*/

.list-unstyled-py-2 {
  > li:not(:last-child) {
    padding-bottom: $list-unstyled-padding-y / 2;
  }

  .list-unstyled-py-2 {
    > li {
      &:first-child {
        padding-top: $list-unstyled-padding-y / 2;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}

.list-unstyled-py-3 {
  > li:not(:last-child) {
    padding-bottom: $list-unstyled-padding-y;
  }

  .list-unstyled-py-3 {
    > li {
      &:first-child {
        padding-top: $list-unstyled-padding-y;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}

.list-unstyled-py-4 {
  > li:not(:last-child) {
    padding-bottom: $list-unstyled-padding-y * 1.5;
  }

  .list-unstyled-py-4 {
    > li {
      &:first-child {
        padding-top: $list-unstyled-padding-y * 1.5;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}