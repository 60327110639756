/*------------------------------------
  Soft Button Styles
------------------------------------*/

@each $color, $value in $theme-colors {
  .btn-soft-#{$color} {
    @include button-soft($value, $value, $value, $value, $value);
  }
}

@each $color, $value in $theme-colors {
  .btn-soft-#{$color} {
    &.dropdown-toggle::after {
      background: str-replace(url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='#{$value}' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E"), "#", "%23") no-repeat right center/$dropdown-toggle-pseudo-width $dropdown-toggle-pseudo-height;
      transition: $btn-transition;
    }

    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      &.dropdown-toggle::after {
        background: str-replace(url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='#{(color-yiq($value))}' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3E%3C/svg%3E"), "#", "%23") no-repeat right center/$dropdown-toggle-pseudo-width $dropdown-toggle-pseudo-height;
      }
    }
  }
}